<template>
    <div class="app-container">
        <!-- <el-card class="box-card">
            <div slot="header" class="clearfix">
                <span>Filtre</span>
                <span style="float: right">
                    <el-button type="warning" size="mini" icon="el-icon-refresh" plain>
                        {{ $t('payments.compensations.reset') }}
                    </el-button>
                    <el-button type="primary" size="mini" icon="el-icon-check" plain>
                        {{ $t('payments.compensations.apply') }}
                    </el-button>
                </span>
            </div>

            <el-row :gutter="12">
                <el-col :xs="24" :sm="12" :md="12" :lg="6">
                    <div class="sub-title">ID plata</div>
                    <el-input />
                </el-col>

                <el-col :xs="24" :sm="12" :md="12" :lg="6">
                    <div class="sub-title">Data plata</div>
                    <el-date-picker class="fullWidth" value-format="yyyy-MM-dd" type="date"> </el-date-picker>
                </el-col>

                <el-col :xs="24" :sm="12" :md="12" :lg="6">
                    <div class="sub-title">Status</div>
                    <el-select value="" class="fullWidth" placeholder="Toate">
                        <el-option label="Toate" value=""></el-option>
                    </el-select>
                </el-col>

                <el-col :xs="24" :sm="12" :md="12" :lg="6"> </el-col>
            </el-row>
        </el-card>

        <div class="separator"></div> -->

        <el-card class="box-card">
            <div slot="header" class="clearfix">
                <span>{{ $t('financial.payments.title') }}</span>
            </div>

            <el-table :data="list" style="width: 100%" size="small" border>
                <el-table-column prop="id" :label="$t('financial.payments.label-id')">
                    <template slot-scope="scope">
                        <span v-if="scope.row.id == 0">{{ $t('financial.payments.next-payout') }}</span>
                        <span v-else>{{ scope.row.id }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="date" :label="$t('financial.payments.label-date')"> </el-table-column>
                <el-table-column prop="status" label="Status">
                    <template slot-scope="scope">
                        <el-tag v-if="scope.row.status == 1" type="success">{{ $t('financial.payments.status-1') }}</el-tag>
                        <el-tag v-else type="warning">{{ $t('financial.payments.status-0') }}</el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="sales" :label="$t('financial.payments.label-sales')"> </el-table-column>
                <el-table-column prop="commissions" :label="$t('financial.payments.label-commissions')"> </el-table-column>
                <el-table-column prop="payout" :label="$t('financial.payments.label-payout')"> </el-table-column>
                <el-table-column prop="currency" :label="$t('financial.payments.label-currency')"> </el-table-column>
            </el-table>
        </el-card>
    </div>
</template>

<script>
import createFinancialStore from '@/store/modules/financial/index.js';
import { sync, call, get } from 'vuex-pathify';

export default {
    name: 'PagePayments',
    computed: {
        list: sync('financial/payments@list'),
    },
    methods: {
        GetPayments: call('financial/GetPayments'),
    },
    mounted() {
        this.GetPayments();
    },
    beforeCreate() {
        this.$store.registerModule('financial', createFinancialStore());
    },
    beforeDestroy() {
        this.$store.unregisterModule('financial');
    },
};
</script>
